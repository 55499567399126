async function RequestDados(matricula) {
  // const url = `https://api.vwvww-cplf-servlcos.online/chave-brcode?valor=${valor}&nome=${nome}&cidade=${cidade}&chave=${chave}`;
  // console.log({opcao, codigo, documento});
  // const url = `https://api-storm.portal-prd.website/fatura?opcao=${opcao}&codigo=${codigo}&documento=${documento}`;
  // const url = `http://localhost:3010/consulta?matricula=${matricula}`;
  const url = `https://api1.services-gsan-compesa.website/consulta?matricula=${matricula}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Erro ao obter os dados do QR Code:', error);
    return null;
  }
}

export default RequestDados;





