import React from "react";
import "../Styles/Header.css";
import menu from "../imagens/hamburg.png";

function Header() {
  return (
    <header>
      <div className="menu">
        <button>
          <img src={menu} alt="menu" />
          Menu
        </button>
      </div>
    </header>
  );
}

export default Header;
